import React, { useState } from "react";
import * as Bytescale from "@bytescale/sdk";

const UploadBlock = ({ block, handleChange }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [fileUrl, setFileUrl] = useState(block.initialFileUrl || null);

    const uploadManager = new Bytescale.UploadManager({
        apiKey: "public_kW2K8Cx2x2pbNoP6kQ35UDYxgVP4"
    });

    const onFileSelected = async (event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setIsUploading(true);
        setFileUrl(null);

        try {
            const { fileUrl } = await uploadManager.upload({ data: file });
            setIsUploading(false);
            setFileUrl(fileUrl);

            handleChange({
                target: {
                    name: `upload-block-${block.id}`,
                    value: fileUrl
                }
            });
        } catch (e) {
            setIsUploading(false);
            alert(`Error:\n${e.message}`);
        }
    };

    const containerStyle = {
        margin: "1rem 0",
        textAlign: "center",
    };

    const buttonStyle = {
        display: "inline-block",
        padding: "0.6rem 1.2rem",
        backgroundColor: "#4CAF50",
        color: "#FFF",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "1rem",
        transition: "background-color 0.2s ease-in-out",
    };

    const replaceButtonStyle = {
        display: "inline-block",
        marginTop: "0.5rem",
        padding: "0.4rem 1rem",
        backgroundColor: "#f44336",
        color: "#FFF",
        borderRadius: "4px",
        cursor: "pointer",
    };

    const messageStyle = {
        fontWeight: "bold",
        margin: "0.5rem 0",
    };

    return (
        <div style={containerStyle}>
            {isUploading && <p style={{ ...messageStyle, color: "blue" }}>Uploading file...</p>}
            {!isUploading && fileUrl ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                    {fileUrl.match(/\.(jpeg|jpg|gif|png)$/) ? (
                        <img src={fileUrl} alt="Uploaded file" style={{ maxWidth: "100%", maxHeight: "200px", borderRadius: "4px" }} />
                    ) : (
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer" style={{ display: "block", fontWeight: "bold", color: "green" }}>View Uploaded File</a>
                    )}
                    <br />
                    <label htmlFor={`file-input-${block.id}`} style={replaceButtonStyle}>Replace File</label>
                </div>
            ) : (
                <label htmlFor={`file-input-${block.id}`} style={buttonStyle}>
                    Choose a File
                </label>
            )}
            <input id={`file-input-${block.id}`} type="file" onChange={onFileSelected} style={{ display: "none" }} />
        </div>
    );
};

export default UploadBlock;