import { gql } from "@apollo/client";

export const GET_FORM_QUERY = gql`
fragment donationBlockFields on ComponentBlocksDonationBlock {
  donation_block {
            id
            name
            label
            sort
            allows_any_donation_amount
            donation_style
            donation_description
            image {
              url
            }
            donation_amounts {
              description
              amount
            }
            checkoutLabel
\t}
}

fragment twoColumnFields on ComponentBlocksTwoColumnBlock {
    id
    two_column_block {
        id
        leftColumn {
        ... on ComponentBlocksDonationBlock {
            ...donationBlockFields
        }
        ... on ComponentBlocksQuestionBlock {\t
            ...questionBlockFields
        }
        ... on ComponentBlocksCheckoutBlock {
            ...checkoutBlockFields
        }
        ... on ComponentBlocksProductBlock {
            ...productFields
        }
        ... on ComponentBlocksP2Block {
            ...personFields
        }
        ... on ComponentBlocksPatronInformationBlock {
            ...patronFields
        }
         ... on ComponentBlocksBuyersBlock {
            ...buyersBlockFields
        }
        ... on ComponentBlocksHtmlBlock {
            ...htmlFields
        }
        ... on ComponentBlocksDonationGoalBlock {
           goal
           toDateText
           giftsText
           goalText
           giftsSingleItemText
           biasDollars
           biasNumGifts
        }
        ... on ComponentBlocksDonationGoalNameEntryBlock {
           optInText
        }
        ... on ComponentBlocksDonationSocialMediaShareBlock {
           headerText
           sharedQuote
           sharedImage {
                url
          }
        }
        ... on ComponentBlocksDonationGoalRecentDonorsList {
           sortOrder
           listType
           headerText
        }
        }
        rightColumn {
        ... on ComponentBlocksDonationBlock {
            ...donationBlockFields
        }
        ... on ComponentBlocksQuestionBlock {\t
            ...questionBlockFields
        }
        ... on ComponentBlocksCheckoutBlock {
            ...checkoutBlockFields
        }
        ... on ComponentBlocksProductBlock {
            ...productFields
        }
        ... on ComponentBlocksP2Block {
            ...personFields
        }
        ... on ComponentBlocksPatronInformationBlock {
            ...patronFields
        }
        ... on ComponentBlocksBuyersBlock {
            ...buyersBlockFields
        }
        ... on ComponentBlocksHtmlBlock {
            ...htmlFields
        }
        ... on ComponentBlocksDonationGoalBlock {
           goal
           toDateText
           giftsText
           goalText
           giftsSingleItemText
           biasDollars
           biasNumGifts
        }
        ... on ComponentBlocksDonationGoalNameEntryBlock {
           optInText
        }
        ... on ComponentBlocksDonationSocialMediaShareBlock {
           headerText
           sharedQuote
           sharedImage {
                url
            }
        }
        ... on ComponentBlocksDonationGoalRecentDonorsList {
           sortOrder
           listType
           headerText
        }
        }
    }
}

fragment questionBlockFields on ComponentBlocksQuestionBlock {
    question_block {
        id
        label
        type
        rows
        columns
        required
        question_block_answers(sort: "priority:desc") {
            id
            text
        }
    }
}

fragment checkoutBlockFields on ComponentBlocksCheckoutBlock {
    id
    label
}

fragment productFields on ComponentBlocksProductBlock {
    products_block {
            id
            header
            footer
            checkboxDisplay
            image {
                url
              }
            products(sort: "priority:desc") {
              id
              fee
              image {
                url
              }
              description
              minQuantity
              maxQuantity
              quantityAvailable
              amountSold
              product_activity_in_processes {
                quantity
              }
              productActivityInProgressText
              displayName
              price
              name
            }
    }
}

fragment buyersBlockFields on ComponentBlocksBuyersBlock {
    id
    label
    BuyersBlockFields {
        address {
            required
        }
        phone {
            required
            type
        }
    }
}


fragment patronFields on ComponentBlocksPatronInformationBlock {
    id
    label
    PatronFields {
        address {
            required
        }
        phone {
            required
            type
        }
        dob {
            required
        }
        birthday {
            required
        }
    }
}

fragment htmlFields on ComponentBlocksHtmlBlock {
    id
    html_block {
        id
        html
    }
}

fragment personFields on ComponentBlocksP2Block {
    person_block {
        id
        label
        fields {
            ... on ComponentPerson2ComponentsNameFields {
            required
            }
            ... on ComponentPerson2ComponentsAddressFields {
            required
            }
            ... on ComponentPerson2ComponentsPhoneFields {
            type
            required
            }
            ... on ComponentPerson2ComponentsRelationshipFields {
            label
            required
            }
            ... on ComponentPerson2ComponentsEmailAddressField {
            required
            }
            ... on ComponentPerson2ComponentsDateOfBirthFields {
            required
            }
            ... on ComponentPerson2ComponentsBirthdayField {
            required
            }
        }
    }
}

query page($venue: String!, $path: String!) {
  forms(where:{ venue: {path: $venue}, path: $path}) {
    id
    name
    googleAnaltyicsId
    successHTML
    redirectToURL
    failureHTML
    formType
    label
    css
    showCartTotal
    skipShoppingCart
    testMode
    showSocialMediaShareButtonsOnCheckout
    donationSettings {
      rate
      beforeText
      afterText
      placement
      selectedByDefault
    }
    form_fees {
      label
      name
      amount
      type
      applied_per
      item_id
    }
    venue {
      id
      css
      primaryColor
      secondaryColor
      website
      webhookErrorEmail
      hideComments
      hideCustRef
      remote_stylesheets {
        url
      }
      logo {
        url
      }
      tag
      tunl_username
    }
    pages {
      id
      productsRequired
      css
      label
      blocks {
       ... on ComponentBlocksComponentUploadBlock {
          id
          url
        }
        ... on ComponentBlocksDonationGoalBlock {
           goal
           toDateText
           giftsText
           goalText
           giftsSingleItemText
           biasDollars
           biasNumGifts
        }
        ... on ComponentBlocksDonationGoalNameEntryBlock {
           optInText
        }
        ... on ComponentBlocksDonationSocialMediaShareBlock {
           headerText
           sharedQuote
           sharedImage {
                url
           }
        }
        ... on ComponentBlocksDonationGoalRecentDonorsList {
           sortOrder
           listType
           headerText
        }
        ... on ComponentBlocksTwoColumnBlock {
            ...twoColumnFields
        }
        ... on ComponentBlocksDonationBlock {
            ...donationBlockFields
        }
        ... on ComponentBlocksQuestionBlock {\t
            ...questionBlockFields
        }
        ... on ComponentBlocksCheckoutBlock {
            ...checkoutBlockFields
        }
        ... on ComponentBlocksProductBlock {
            ...productFields
        }
        ... on ComponentBlocksP2Block {
            ...personFields
        }
        ... on ComponentBlocksPatronInformationBlock {
            ...patronFields
        }
        ... on ComponentBlocksBuyersBlock {
            ...buyersBlockFields
        }
        ... on ComponentBlocksHtmlBlock {
            ...htmlFields
        }
      }
    }
  }
 }`;
