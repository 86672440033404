import React from "react";
import DonationBlock from "../DonationBlock/DonationBlock";
import QuestionBlock from "../QuestionBlock/QuestionBlock";
import ProductsBlock from "../ProductBlock/ProductsBlock";
import HTMLBlock from "../HTMLBlock/HTMLBlock";
import PatronInformationBlock from "../PatronInformationBlock/PatronInformationBlock";
import PersonBlock from "../PersonBlock/PersonBlock";
import CheckoutForm from "../Checkout/Checkout";
import TwoColumnBlock from "../TwoColumnBlock/TwoColumnBlock";
import DonationGoalBlock from "../DonationGoalBlock/DonationGoalBlock";
import DonationGoalNameEntryBlock from "../DonationGoalNameEntryBlock/DonationGoalNameEntryBlock";
import DonationGoalRecentDonorsList from "../DonationGoalRecentDonorsList/DonationGoalRecentDonorsList";
import DonationSocialMediaShareBlock from "../DonationSocialMediaShareBlock/DonationSocialMediaShareBlock";
import BuyersBlock from "../BuyersBlock/BuyersBlock";
import UploadBlock from "../UploadBlock/UploadBlock";

const Block = (props) => {
    return (
        <>
                {props.block.donation_block && (
                    <DonationBlock block={props.block.donation_block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.question_block && (
                    <QuestionBlock block={props.block.question_block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.products_block && (
                    <ProductsBlock block={props.block.products_block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.html_block && (
                    <HTMLBlock block={props.block.html_block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.__typename === "ComponentBlocksPatronInformationBlock" && (
                    <PatronInformationBlock block={props.block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.__typename === "ComponentBlocksBuyersBlock" && (
                    <BuyersBlock block={props.block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.person_block && (
                    <PersonBlock block={props.block.person_block} form={props.form} handleChange={props.handleChange} />
                )}
                {props.block.__typename === "ComponentBlocksCheckoutBlock" && (
                    <CheckoutForm form={props.form} handleChange={props.handleChange} handleFormSubmission={props.handleFormSubmission} />
                )}
                {props.block.__typename === "ComponentBlocksTwoColumnBlock" && (
                    <TwoColumnBlock block={props.block} form={props.form} handleChange={props.handleChange}  />
                )}
                {props.block.__typename === "ComponentBlocksDonationGoalBlock" && (
                    <DonationGoalBlock block={props.block} form={props.form} handleChange={props.handleChange}  />
                )}
                {props.block.__typename === "ComponentBlocksDonationGoalNameEntryBlock" && (
                    <DonationGoalNameEntryBlock block={props.block} form={props.form} handleChange={props.handleChange}  />
                )}
                {props.block.__typename === "ComponentBlocksDonationGoalRecentDonorsList" && (
                    <DonationGoalRecentDonorsList block={props.block} form={props.form} handleChange={props.handleChange}  />
                )}
                {props.block.__typename === "ComponentBlocksDonationSocialMediaShareBlock" && (
                    <DonationSocialMediaShareBlock headerText={props.block.headerText} sharedQuote={props.block.sharedQuote} sharedImage={props.block.sharedImage} />
                )}
                {props.block.__typename === "ComponentBlocksComponentUploadBlock" && (
                    <UploadBlock block={props.block} form={props.form} handleChange={props.handleChange} />
                )}
        </>
    )
}

export default Block;
